import getObjects from './base'
import * as types from '@/store/mutation-types'

const state = {
  forms: JSON.parse(localStorage.getItem('forms')) || [],
  levelTypes: JSON.parse(localStorage.getItem('levelTypes')) || [],
  financingTypes: JSON.parse(localStorage.getItem('financingTypes')) || []
}

const getters = {
  forms: state => state.forms,
  formsCheck: state => state.forms.length > 0,

  levelTypes: state => state.levelTypes,
  levelTypesCheck: state => state.levelTypes.length > 0,

  financingTypes: state => state.financingTypes,
  financingTypesCheck: state => state.financingTypes.length > 0
}

const mutations = {
  [types.SET_FORMS](state, { objects }) {
    state.forms = objects
    localStorage.setItem('forms', JSON.stringify(objects))
  },
  [types.REMOVE_FORMS](state) {
    state.forms = []
    localStorage.removeItem('forms')
  },

  [types.SET_LEVEL_TYPES](state, { objects }) {
    state.levelTypes = objects
    localStorage.setItem('levelTypes', JSON.stringify(objects))
  },
  [types.REMOVE_LEVEL_TYPES](state) {
    state.levelTypes = []
    localStorage.removeItem('levelTypes')
  },

  [types.SET_FINANCING_TYPES](state, { objects }) {
    state.financingTypes = objects
    localStorage.setItem('financingTypes', JSON.stringify(objects))
  },
  [types.REMOVE_FINANCING_TYPES](state) {
    state.financingTypes = []
    localStorage.removeItem('financingTypes')
  }
}

const actions = {
  async getForms({ commit, getters }) {
    return getObjects(
      commit,
      'educations/forms/',
      types.SET_FORMS,
      getters.formsCheck,
      getters.forms
    )
  },

  async getLevelTypes({ commit, getters }) {
    return getObjects(
      commit,
      'educations/level-types/',
      types.SET_LEVEL_TYPES,
      getters.levelTypesCheck,
      getters.levelTypes
    )
  },

  async getFinancingTypes({ commit, getters }) {
    return getObjects(
      commit,
      'educations/financing-types/',
      types.SET_FINANCING_TYPES,
      getters.financingTypesCheck,
      getters.financingTypes
    )
  }
}

export default {
  getters,
  state,
  mutations,
  actions
}
