import getObjects from './base'
import * as types from '@/store/mutation-types'

const state = {
  contractTypes: JSON.parse(localStorage.getItem('contractTypes')) || []
}

const getters = {
  contractTypes: state => state.contractTypes,
  contractTypesCheck: state => state.contractTypes.length > 0
}

const mutations = {
  [types.SET_CONTRACT_TYPES](state, { objects }) {
    state.contractTypes = objects
    localStorage.setItem('contractTypes', JSON.stringify(objects))
  },
  [types.REMOVE_CONTRACT_TYPES](state) {
    state.contractTypes = []
    localStorage.removeItem('contractTypes')
  }
}

const actions = {
  async getContractTypes({ commit, getters }) {
    return getObjects(
      commit,
      'contracts/contract-types/',
      types.SET_CONTRACT_TYPES,
      getters.contractTypesCheck,
      getters.contractTypes
    )
  }
}

export default {
  getters,
  state,
  mutations,
  actions
}
