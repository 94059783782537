<template>
  <div
    class="position-relative"
    ref="wrapper"
    style="cursor: help;"
    :title="title"
    :data-placement="tooltipPosition"
  >
    <slot></slot>
    <i
      v-if="help"
      class="mdi mdi-help-circle-outline position-absolute"
      style="top: -16px; right: -10px;"
    ></i>
  </div>
</template>

<script>
  import $ from 'jquery'

  export default {
    name: 'app-tooltip',
    props: ['title', 'help', 'tooltipPosition'],
    mounted() {
      const wrapper = this.$refs.wrapper
      $(wrapper)
        .tooltip({
          html: true,
          trigger: 'hover'
        })
        .on('click mousedown mouseup', function() {
          $(wrapper).tooltip('hide')
        })
    },
    updated() {
      const wrapper = this.$refs.wrapper
      $(wrapper).tooltip('dispose')
      $(wrapper)
        .tooltip({
          html: true,
          trigger: 'hover'
        })
        .on('click mousedown mouseup', function() {
          $(wrapper).tooltip('hide')
        })
    },
    beforeDestroy() {
      const wrapper = this.$refs.wrapper
      $(wrapper).tooltip('dispose')
    }
  }
</script>
