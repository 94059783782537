<template>
  <div class="form-check form-check-inline">
    <input
      class="form-check-input m-0"
      type="radio"
      :id="uuid"
      :value="value"
      :checked="checked"
      @change="update"
      :disabled="disabled"
    />
    <label class="pl-2 form-check-label" :for="uuid">
      {{ name }}
    </label>
  </div>
</template>

<script>
  import { v1 as uuidv1 } from 'uuid'

  export default {
    name: 'app-radio',
    model: {
      prop: 'modelValue',
      event: 'change'
    },
    props: ['value', 'modelValue', 'name', 'disabled'],
    computed: {
      uuid: () => uuidv1(),
      checked() {
        return this.modelValue === this.value
      }
    },
    methods: {
      update() {
        this.$emit('change', this.value)
      }
    }
  }
</script>
