const Identity = () =>
  import(
    /* webpackChunkName: "lk__documents__identity" */ '@/pages/lk/documents/identity'
  ).then(m => m.default || m)
const Education = () =>
  import(
    /* webpackChunkName: "lk__documents__education" */ '@/pages/lk/documents/education'
  ).then(m => m.default || m)
const Achievements = () =>
  import(
    /* webpackChunkName: "lk__documents__achievements" */ '@/pages/lk/documents/achievements'
  ).then(m => m.default || m)
const Benefit = () =>
  import(
    /* webpackChunkName: "lk__documents__benefit" */ '@/pages/lk/documents/benefit'
  ).then(m => m.default || m)
const Others = () =>
  import(
    /* webpackChunkName: "lk__documents__others" */ '@/pages/lk/documents/others'
  ).then(m => m.default || m)
// const Targets = () =>
//   import(
//     /* webpackChunkName: "lk__documents__targets" */ '@/pages/lk/documents/targets'
//   ).then(m => m.default || m)
// const OtherIdentities = () =>
//   import(
//     /* webpackChunkName: "lk__documents__other-identities" */ '@/pages/lk/documents/other-identities'
//   ).then(m => m.default || m)

export default [
  { path: '/identity/', name: 'identity', component: Identity },
  { path: '/education/', name: 'education', component: Education },
  { path: '/achievements/', name: 'achievements', component: Achievements },
  { path: '/benefit/', name: 'benefit', component: Benefit },
  { path: '/others/', name: 'others', component: Others }
  // {
  //   path: '/other-identities/',
  //   name: 'otherIdentities',
  //   component: OtherIdentities
  // },
  // { path: '/targets/', name: 'targets', component: Targets }
]
