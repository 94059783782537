const Add = () =>
  import(
    /* webpackChunkName: "admin__statements__add" */ '@/pages/statements'
  ).then(m => m.default || m)
// const Edit = () =>
//   import(
//     /* webpackChunkName: "admin__statements__edit" */ '@/pages/admin/statements/edit'
//   ).then(m => m.default || m)

export default [
  { path: '/add/:id', name: 'add', component: Add }
  // { path: '/edit/:entrantId/:id', name: 'edit', component: Edit }
]
