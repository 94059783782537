const Exams = () =>
  import(/* webpackChunkName: "admin__exams" */ '@/pages/admin/exams').then(
    m => m.default || m
  )
const Print = () =>
  import(
    /* webpackChunkName: "admin__exams__edit" */ '@/pages/admin/exams/print'
  ).then(m => m.default || m)
const Result = () =>
  import(
    /* webpackChunkName: "admin__exams__edit" */ '@/pages/admin/exams/result'
  ).then(m => m.default || m)
const Moodle = () =>
  import(
    /* webpackChunkName: "admin__exams__edit" */ '@/pages/admin/exams/moodle'
  ).then(m => m.default || m)

export default [
  { path: '/', name: 'input', component: Exams },
  { path: '/print', name: 'print', component: Print },
  { path: '/result', name: 'result', component: Result },
  { path: '/moodle', name: 'moodle', component: Moodle }
]
