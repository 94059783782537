export const state = {
  adminItems: [
    {
      name: 'Абитуриенты',
      role: 'all',
      items: [
        {
          icon: 'mdi mdi-magnify',
          name: 'Поиск',
          to: { name: 'admin.search' },
          isNotReload: true
        },
        {
          icon: 'mdi mdi-account-plus',
          name: 'Добавить',
          to: { name: 'admin.check-entrant' },
          isNotReload: true
        },
        {
          icon: 'mdi mdi-account-multiple-plus',
          name: 'Новые',
          to: { name: 'admin.search-new' },
          isNotReload: true
        },
        {
          icon: 'mdi mdi-account-edit',
          name: 'Измененные',
          to: { name: 'admin.search-edit' },
          isNotReload: true
        },
        {
          icon: 'mdi mdi-trophy-award',
          name: 'Рейтинги',
          to: { name: 'rating.index' },
          isNotReload: true
        }
      ]
    },
    {
      name: 'Служебное',
      role: 'staff',
      items: [
        {
          icon: 'mdi mdi-briefcase-search',
          name: 'Договоры',
          to: { name: 'admin.contracts.search' },
          isNotReload: true
        },
        {
          icon: 'fas fa-file-invoice',
          name: 'Ведомости',
          subitems: [
            {
              icon: 'mdi mdi-printer',
              name: 'Печать',
              to: { name: 'admin.exams.print' },
              isNotReload: true
            },
            {
              icon: 'mdi mdi-keyboard',
              name: 'Ввод оценок',
              to: { name: 'admin.exams.input' },
              isNotReload: true
            },
            {
              icon: 'fas fa-award',
              name: 'Результаты экзаменов',
              to: { name: 'admin.exams.result' },
              isNotReload: true
            },
            {
              icon: 'fas fa-graduation-cap',
              name: 'Для Moodle',
              to: { name: 'admin.exams.moodle' },
              isNotReload: true
            }
          ]
        },
        {
          icon: 'mdi mdi-view-list',
          name: 'Зачисление',
          subitems: [
            {
              icon: 'mdi mdi-account-check',
              name: 'Зачислить',
              to: { name: 'admin.enrollment.enrollment' },
              isNotReload: true
            },
            {
              icon: 'fas fa-file-contract',
              name: 'Приказы',
              to: { name: 'admin.enrollment.print' },
              isNotReload: true
            }
          ]
        },
        // {
        //   icon: 'mdi mdi-rocket',
        //   name: 'Ускоренники',
        //   subitems: [
        //     {
        //       icon: 'mdi mdi-plus-circle',
        //       name: 'Добавить',
        //       to: { name: 'admin.accelerator.add' },
        //       isNotReload: true
        //     }
        //   ]
        // },
        {
          icon: 'mdi mdi-file-document-outline',
          name: 'Отчеты',
          subitems: [
            {
              icon: 'mdi mdi-run',
              name: 'Поступающие',
              to: { name: 'admin.reports.list', hash: '#coming' },
              isNotReload: true
            },
            {
              icon: 'mdi mdi-check-all',
              name: 'Проверки',
              to: { name: 'admin.reports.list', hash: '#check' },
              isNotReload: true
            },
            {
              icon: 'mdi mdi-account-check',
              name: 'Зачисленные',
              to: { name: 'admin.reports.list', hash: '#enrolled' },
              isNotReload: true
            },
            {
              icon: 'mdi mdi-briefcase-account',
              name: 'Договоры',
              to: { name: 'admin.reports.list', hash: '#contract' },
              isNotReload: true
            },
            {
              icon: 'mdi mdi-briefcase-account',
              name: 'По филиалам',
              to: { name: 'admin.reports.list', hash: '#branches' },
              isNotReload: true
            }
          ]
        },
        {
          icon: 'mdi mdi-chart-bar',
          name: 'Статистика',
          to: { name: 'admin.statistics' },
          isNotReload: true
        },
        // {
        //   icon: 'mdi mdi-chart-bar',
        //   name: 'ФИС ГИА',
        //   to: { name: 'admin.statistics' },
        //   isNotReload: true
        // },
        {
          icon: 'mdi mdi-school',
          name: 'Деканат',
          to: { name: 'admin.deanery' },
          isNotReload: true
        }
      ]
    },
    {
      name: 'Администратор',
      role: 'admin',
      items: [
        {
          icon: 'fas fa-tachometer-alt',
          name: 'Панель управления',
          to: '/dj-admin/'
        },
        {
          icon: 'mdi mdi-database',
          name: 'База данных',
          to: '/sql/'
        },
        {
          icon: 'mdi mdi-api',
          name: 'API',
          to: '/api/'
        }
      ]
    }
  ],
  publicItems: [
    {
      name: 'Абитуриент',
      role: 'all',
      items: [
        {
          icon: 'mdi mdi-bell',
          name: 'Объявления',
          to: { name: 'lk.notifications' },
          isNotReload: true
        },
        {
          icon: 'mdi mdi-trophy-award',
          name: 'Рейтинги',
          to: { name: 'rating.index' },
          isNotReload: true
        },
        {
          icon: 'mdi mdi-account-cog',
          name: 'Настройки',
          to: { name: 'lk.settings' },
          isNotReload: true
        }
      ]
    },
    {
      name: 'Заявления',
      role: 'all',
      items: [
        {
          icon: 'mdi mdi-folder-search',
          name: 'Просмотр',
          to: { name: 'lk.statements.list' },
          isNotReload: true
        },
        {
          icon: 'mdi mdi-folder-plus',
          name: 'Добавить',
          to: { name: 'lk.statements.add' },
          isNotReload: true
        },
        {
          icon: 'mdi mdi-notebook-multiple',
          name: 'Предметы',
          to: { name: 'lk.subjects' },
          isNotReload: true
        }
      ]
    },
    {
      name: 'Договоры',
      role: 'all',
      items: [
        {
          icon: 'mdi mdi-briefcase-account',
          name: 'Договоры',
          to: { name: 'lk.contracts.list' },
          isNotReload: true
        }
        // {
        //   icon: 'mdi mdi-account-cash',
        //   name: 'Плательщики',
        //   to: { name: 'lk.contracts.payers' },
        //   isNotReload: true
        // }
      ]
    },
    {
      name: 'Документы',
      role: 'all',
      items: [
        {
          icon: 'mdi mdi-badge-account-horizontal-outline',
          name: 'Удостоверение личности',
          to: { name: 'lk.documents.identity' },
          isNotReload: true
        },
        {
          icon: 'mdi mdi-certificate-outline',
          name: 'Образование',
          to: { name: 'lk.documents.education' },
          isNotReload: true
        },
        {
          icon: 'mdi mdi-medical-bag',
          name: 'Льготы',
          to: { name: 'lk.documents.benefit' },
          isNotReload: true
        },
        {
          icon: 'mdi mdi-medal-outline',
          name: 'Индивидуальные достижения',
          to: { name: 'lk.documents.achievements' },
          isNotReload: true
        },
        // {
        //   icon: 'mdi mdi-office-building',
        //   name: 'Целевые договоры',
        //   to: { name: 'lk.documents.targets' },
        //   isNotReload: true
        // },
        // {
        //   icon: 'mdi mdi-card-account-details-outline',
        //   name: 'Другие удостоверения личности',
        //   to: { name: 'lk.documents.otherIdentities' },
        //   isNotReload: true
        // },
        {
          icon: 'mdi mdi-file-multiple',
          name: 'Другие',
          to: { name: 'lk.documents.others' },
          isNotReload: true
        }
      ]
    }
  ]
}

export const getters = {
  adminItems: state => state.adminItems,
  publicItems: state => state.publicItems
}
