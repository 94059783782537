import * as types from '@/store/mutation-types'
import getObjects from './base'

const state = {
  genders: JSON.parse(localStorage.getItem('genders')) || [],
  benefitTypes: JSON.parse(localStorage.getItem('benefitTypes')) || [],
  examGrounds: JSON.parse(localStorage.getItem('examGrounds')) || [],
  languages: JSON.parse(localStorage.getItem('languages')) || [],
  examWays: JSON.parse(localStorage.getItem('examWays')) || [],
  subjects: JSON.parse(localStorage.getItem('subjects')) || [],
  subjectsUnite: JSON.parse(localStorage.getItem('subjectsUnite')) || []
}

const getters = {
  genders: state => state.genders,
  gendersCheck: state => state.genders.length > 0,

  benefitTypes: state => state.benefitTypes,
  benefitTypesCheck: state => state.benefitTypes.length > 0,

  examGrounds: state => state.examGrounds,
  examGroundsCheck: state => state.examGrounds.length > 0,

  languages: state => state.languages,
  languagesCheck: state => state.languages.length > 0,

  examWays: state => state.examWays,
  examWaysCheck: state => state.examWays.length > 0,

  subjects: state => state.subjects,
  subjectsCheck: state => state.subjects.length > 0,

  subjectsUnite: state => state.subjectsUnite,
  subjectsUniteCheck: state => state.subjectsUnite.length > 0
}

const mutations = {
  [types.SET_GENDERS](state, { objects }) {
    state.genders = objects
    localStorage.setItem('genders', JSON.stringify(objects))
  },
  [types.REMOVE_GENDERS](state) {
    state.genders = []
    localStorage.removeItem('genders')
  },

  [types.SET_BENEFIT_TYPES](state, { objects }) {
    state.benefitTypes = objects
    state.benefitTypes.unshift({
      id: -1,
      name: 'Общие основания',
      description: '',
      benefits: []
    })
    localStorage.setItem('benefitTypes', JSON.stringify(objects))
  },
  [types.REMOVE_BENEFIT_TYPES](state) {
    state.benefitTypes = []
    localStorage.removeItem('benefitTypes')
  },

  [types.SET_EXAM_GROUNDS](state, { objects }) {
    state.examGrounds = objects
    state.examGrounds.unshift({
      id: -1,
      name: 'Нет',
      shrtName: ''
    })
    localStorage.setItem('examGrounds', JSON.stringify(objects))
  },
  [types.REMOVE_EXAM_GROUNDS](state) {
    state.examGrounds = []
    localStorage.removeItem('examGrounds')
  },

  [types.SET_LANGUAGES](state, { objects }) {
    state.languages = objects
    localStorage.setItem('languages', JSON.stringify(objects))
  },
  [types.REMOVE_LANGUAGES](state) {
    state.languages = []
    localStorage.removeItem('languages')
  },

  [types.SET_EXAM_WAYS](state, { objects }) {
    state.examWays = objects
    localStorage.setItem('examWays', JSON.stringify(objects))
  },
  [types.REMOVE_EXAM_WAYS](state) {
    state.examWays = []
    localStorage.removeItem('examWays')
  },

  [types.SET_SUBJECTS](state, { objects }) {
    state.subjectsUnite = objects
    localStorage.setItem('subjects', JSON.stringify(objects))
  },
  [types.REMOVE_SUBJECTS](state) {
    state.subjectsUnite = []
    localStorage.removeItem('subjects')
  },

  [types.SET_SUBJECTS_UNITE](state, { objects }) {
    state.subjects = objects
    localStorage.setItem('subjectsUnite', JSON.stringify(objects))
  },
  [types.REMOVE_SUBJECTS_UNITE](state) {
    state.subjects = []
    localStorage.removeItem('subjectsUnite')
  }
}

const actions = {
  async getGenders({ commit, getters }) {
    return getObjects(
      commit,
      'others/genders/',
      types.SET_GENDERS,
      getters.gendersCheck,
      getters.genders
    )
  },

  async getBenefitTypes({ commit, getters }) {
    return getObjects(
      commit,
      'others/benefit-types/',
      types.SET_BENEFIT_TYPES,
      getters.benefitTypesCheck,
      getters.benefitTypes
    )
  },

  async getExamGrounds({ commit, getters }) {
    return getObjects(
      commit,
      'others/exam-grounds/',
      types.SET_EXAM_GROUNDS,
      getters.examGroundsCheck,
      getters.examGrounds
    )
  },

  async getLanguages({ commit, getters }) {
    return getObjects(
      commit,
      'others/languages/',
      types.SET_LANGUAGES,
      getters.languagesCheck,
      getters.languages
    )
  },

  async getExamWays({ commit, getters }) {
    return getObjects(
      commit,
      'others/exam-ways/',
      types.SET_EXAM_WAYS,
      getters.examWaysCheck,
      getters.examWays
    )
  },

  async getSubjects({ commit, getters }) {
    return getObjects(
      commit,
      'others/subjects/',
      types.SET_SUBJECTS,
      getters.subjectsCheck,
      getters.subjects
    )
  },

  async getSubjectsUnite({ commit, getters }) {
    return getObjects(
      commit,
      'others/subjects-unite/',
      types.SET_SUBJECTS_UNITE,
      getters.subjectsUniteCheck,
      getters.subjectsUnite
    )
  }
}

export default {
  getters,
  state,
  mutations,
  actions
}
