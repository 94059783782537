<template>
  <div>
    <label class="pl-1 label">
      {{ name }}
      <span v-if="required" class="text-danger">*</span>
    </label>
    <div :class="classGroup">
      <app-radio
        :class="['font-weight-bold', radioClass]"
        :model-value="value"
        v-for="(r, index) in radios"
        :name="r[label]"
        :key="index"
        :value="r[trackBy]"
        @change="change"
        :disabled="disabled"
      />
    </div>
    <!-- <div class="empty-feedback">Нет ошибок</div> -->
    <div class="mb-1 pl-1 invalid-feedback">
      {{ error }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'app-radiogroup',
    props: {
      value: null,
      name: String,
      valid: Boolean,
      useValid: Boolean,
      required: Boolean,
      radios: Array,
      radioClass: {
        type: String,
        default: ''
      },

      label: String,
      trackBy: String,
      disabled: Boolean,
      error: {
        type: String,
        default: 'Обязательное поле'
      }
    },
    computed: {
      classGroup() {
        return {
          'border rounded d-flex flex-wrap': true,
          'justify-content-around pt-2 pb-1': true,
          'border-success': this.useValid && this.valid && !this.disabled,
          'border-danger is-invalid': this.useValid && !this.valid
        }
      }
    },
    methods: {
      change: function(val) {
        this.$emit('input', val)
      }
    }
  }
</script>
