<template>
  <div>
    <label v-if="!hideLabel" :for="uuid" class="pl-1 label">
      {{ name }}
      <span v-if="required" class="text-danger">*</span>
    </label>
    <cleave
      v-if="useMask"
      :options="maskOptions"
      :raw="false"
      type="text"
      :class="classComputedInput"
      :id="uuid"
      :placeholder="placeholder ? placeholder : name"
      :value="value"
      @input="input"
      @blur="blur"
      :disabled="disabled"
    />
    <input
      v-else
      :type="type"
      :class="classComputedInput"
      :id="uuid"
      :placeholder="placeholder ? placeholder : name"
      :value="value"
      @input="input"
      @blur="blur"
      :disabled="disabled"
      :readonly="readonly"
      @keydown.enter="enter"
    />
    <template v-if="useValid">
      <div class="empty-feedback" v-if="!hideLabel">Нет ошибок</div>
      <div class="mb-1 pl-1 invalid-feedback">
        {{ error }}
      </div>
    </template>
  </div>
</template>

<script>
  import Cleave from 'vue-cleave-component'
  import { v1 as uuidv1 } from 'uuid'

  export default {
    name: 'app-textbox',
    components: {
      Cleave
    },
    props: {
      value: String,
      name: String,
      hideLabel: Boolean,
      useMask: Boolean,
      maskOptions: Object,
      valid: Boolean,
      useValid: Boolean,
      placeholder: String,
      required: Boolean,
      disabled: Boolean,
      readonly: Boolean,
      defaultValue: String,
      classInput: {
        type: String,
        default: ''
      },
      error: {
        type: String,
        default: 'Обязательное поле'
      },
      type: {
        type: String,
        default: 'text'
      }
    },
    computed: {
      uuid: () => uuidv1(),
      classComputedInput() {
        const notEmpty = this.value && this.value.length > 0
        const classInput = {
          'form-control': true,
          'is-valid': this.useValid && this.valid && notEmpty && !this.disabled,
          'is-invalid': this.useValid && !this.valid
        }
        classInput[this.classInput] = true
        return classInput
      }
    },
    methods: {
      enter(e) {
        this.$emit('keydown', e)
      },
      input: function(e) {
        if (typeof e === 'string') {
          this.$emit('input', e)
          return
        }
        this.$emit('input', e.target.value)
      },
      blur: function(e) {
        this.$emit('blur', e)
        if (this.defaultValue) {
          if (typeof e === 'string' && !e) {
            this.$emit('input', this.defaultValue)
            return
          }
          if (!e.target.value) {
            this.$emit('input', this.defaultValue)
          }
        }
      }
    }
  }
</script>
