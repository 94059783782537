<template>
  <div>
    <navbar :show-btn="false" />
    <div class="d-flex wrapper" ref="wrapper">
      <child />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'EmptyNavbarLayout'
  }
</script>
