const Enrollment = () =>
  import(
    /* webpackChunkName: "admin__enrollment" */ '@/pages/admin/enrollment'
  ).then(m => m.default || m)
const Print = () =>
  import(
    /* webpackChunkName: "admin__enrollment__edit" */ '@/pages/admin/enrollment/print'
  ).then(m => m.default || m)

export default [
  { path: '/', name: 'enrollment', component: Enrollment },
  { path: '/print', name: 'print', component: Print }
]
