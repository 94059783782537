<template>
  <div
    v-if="status && !isEntrant"
    class="entity-status"
  >
    <div class="entity-status__title">
      <div :style="{ color: currentStatusColor }">{{ currentStatusName }}</div>
      <!-- <div class="entity-status__title-help">текущий статус</div> -->
    </div>
    <div class="entity-status__btns">
      <div
        v-if="status !== 3 && status !== 4"
        class="entity-status__btn status-valid"
        @click.prevent="showDialog(3)"
      >
        <i class="mdi mdi-account-check"></i>
      </div>
      <div
        v-if="!onCancel && (status === 3 || status === 5 || status === 1)"
        class="entity-status__btn status-updated"
        @click.prevent="showDialog(2)"
      >
        <i class="mdi mdi-account-alert"></i>
      </div>
      <div
        v-if="onCancel && (status === 3 || status === 2 || status === 1)"
        class="entity-status__btn status-cancel"
        @click.prevent="showDialog(5)"
      >
        <i class="mdi mdi-account-cancel"></i>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import ModalMixin from '@/components/mixins/ModalMixin'
export default {
  mixins: [ModalMixin],
  props: {
    value: Number,
    entityType: String,
    entityId: Number,
    onCancel: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      statusList: [
        { id: 1, color: '#ed4949', name: 'Новое' },
        { id: 2, color: '#d5b500', name: 'Изменено' },
        { id: 3, color: 'green', name: 'Проверено' },
        { id: 4, color: 'green', name: 'Проверено и Отправлено' },
        { id: 5, color: 'red', name: 'Отклонено' }
      ],
      status: null
    }
  },
  computed: {
    ...mapGetters({
      isEntrant: 'auth/checkEntrant'
    }),
    currentStatusName() {
      if (this.status) {
        return this.getStatusName(this.status)
      }
      return ''
    },
    currentStatusColor() {
      if (this.status) {
        return this.getStatusColor(this.status)
      }
      return ''
    }
  },
  created() {
    this.status = this.value
  },
  validations: {},
  methods: {
    getStatusName(id) {
      return this.statusList.find(s => s.id === id).name
    },
    getStatusColor(id) {
      return this.statusList.find(s => s.id === id).color
    },
    change(newId) {
      axios
        .post('entrants/entity-status/', {
          status: newId,
          entityType: this.entityType,
          entityId: this.entityId
        })
        .then(r => {
          if (r.status === 200) {
            this.status = newId
            this.$emit('input', newId)
          }
        })
    },
    showDialog(id) {
      this.showConfirm({
        title: 'Требуется подтверждение',
        text: `Вы уверены что хотите изментить статус ${this.getStatusName(
          this.status
        )} на ${this.getStatusName(id)}?`,
        icon: 'warning',
        // callback: result => {
        //   this.status = id
        // },
        preConfirm: obj => {
          return this.change(id)
        }
      })
    }
  }
}
</script>
