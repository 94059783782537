<template>
  <div>
    <label class="pl-1 label">
      {{ name }}
      <span
        v-if="required"
        class="text-danger"
      >*</span>
    </label>
    <multiselect
      :class="classSelect"
      :options="options"
      :value="value"
      :label="label"
      :track-by="trackBy"
      :allow-empty="allowEmpty"
      select-label=""
      selected-label=""
      deselect-label=""
      placeholder="Поиск"
      @input="change"
      :disabled="disabled"
      :multiple="multiple"
    >
      <span slot="noOptions">Список пуст</span>
      <span slot="noResult">Ничего не найдено</span>
    </multiselect>
    <template v-if="useValid">
      <div class="empty-feedback">Нет ошибок</div>
      <div class="mb-1 pl-1 invalid-feedback">
        {{ error }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'app-multiselect',
  props: {
    value: Object,
    // value: {},
    name: String,
    valid: Boolean,
    useValid: Boolean,
    required: Boolean,
    options: Array,
    label: String,
    trackBy: String,
    disabled: Boolean,
    allowEmpty: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: 'Обязательное поле'
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classSelect() {
      return {
        'is-valid': this.useValid && this.valid && !this.disabled,
        'is-invalid': this.useValid && !this.valid
      }
    }
  },
  created() {
    // if (Number.isInteger(this.value)) {
    //   const newValue = this.options.filter(s => s.id === this.value)[0]
    //   this.$emit('input', newValue)
    // }
  },
  methods: {
    change: function (value, id) {
      this.$emit('input', value)
    }
  }
}
</script>
