import address from './references/address'
import admission from './references/admission'
import other from './references/other'
import colleges from './references/colleges'
import documentTypes from './references/document-types'
import competitions from './references/competitions'
import contracts from './references/contracts'
import education from './references/education'
import * as types from '../mutation-types'

export const state = {
  ...other.state,
  ...documentTypes.state,
  ...colleges.state,
  ...address.state,
  ...admission.state,
  ...competitions.state,
  ...contracts.state,
  ...education.state
}

export const getters = {
  ...other.getters,
  ...documentTypes.getters,
  ...colleges.getters,
  ...address.getters,
  ...admission.getters,
  ...competitions.getters,
  ...contracts.getters,
  ...education.getters
}

export const mutations = {
  ...other.mutations,
  ...documentTypes.mutations,
  ...colleges.mutations,
  ...address.mutations,
  ...admission.mutations,
  ...competitions.mutations,
  ...contracts.mutations,
  ...education.mutations
}

export const actions = {
  ...other.actions,
  ...documentTypes.actions,
  ...colleges.actions,
  ...address.actions,
  ...admission.actions,
  ...competitions.actions,
  ...contracts.actions,
  ...education.actions,

  async clear({ commit }) {
    commit(types.REMOVE_COLLEGES)
    commit(types.REMOVE_GENDERS)
    commit(types.REMOVE_COLLEGE_COMPETITIONS)
    commit(types.REMOVE_EDUCATION_DOCUMENT_TYPES)
    commit(types.REMOVE_IDENTITY_DOCUMENT_TYPES)
    commit(types.REMOVE_REGIONS)
    commit(types.REMOVE_SETTLEMENT_TYPES)
    commit(types.REMOVE_STREET_TYPES)
    commit(types.REMOVE_BENEFIT_TYPES)
    commit(types.REMOVE_ADMISSION_CAMPAIGNS)
    commit(types.REMOVE_EXAM_GROUNDS)
    commit(types.REMOVE_LANGUAGES)
    commit(types.REMOVE_COMPETITIONS)
    commit(types.REMOVE_ADMISSION_DEPARTMENTS)
    commit(types.REMOVE_CONTRACT_TYPES)
    commit(types.REMOVE_FORMS)
    commit(types.REMOVE_LEVEL_TYPES)
    commit(types.REMOVE_FINANCING_TYPES)
    commit(types.REMOVE_EXAM_WAYS)
    commit(types.REMOVE_SUBJECTS)
  }
}
