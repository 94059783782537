<template>
  <aside class="sidebar">
    <nav class="sidebar__wrapper d-flex shadow">
      <ul class="accordion sidebar__nav sidebar__nav--primary">
        <template v-for="(category, catIndex) in getCategory">
          <template v-if="checkRole(category.role)">
            <li class="sidebar__category w-100" :key="category.name">
              {{ category.name }}
            </li>
            <sb-item
              v-for="(item, index) in category.items"
              :key="category.name + item.name"
              :id="`nav-collapse-${catIndex}-${index}`"
              :item="item"
            ></sb-item>
          </template>
        </template>
      </ul>
    </nav>
  </aside>
</template>

<script>
  import Item from './Item'

  export default {
    name: 'sidebar',
    props: {
      isAdmin: {
        type: Boolean,
        default: true
      }
    },
    components: {
      'sb-item': Item
    },
    computed: {
      getCategory: function() {
        if (this.isAdmin) {
          return this.$store.getters['menu/adminItems']
        }
        return this.$store.getters['menu/publicItems']
      }
    },
    methods: {
      checkRole: function(role) {
        return this.$store.getters['auth/checkRole'](role)
      }
    }
  }
</script>
