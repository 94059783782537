import axios from 'axios'

function getApiObjects(commit, url, type) {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(response => {
        const objects = response.data
        commit(type, { objects })
        resolve(objects)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function getObjects(commit, url, type, check, objects) {
  if (!check) {
    return getApiObjects(commit, url, type)
  }
  return new Promise((resolve, reject) => {
    resolve(objects)
  })
}

export default getObjects
