import getObjects from './base'
import * as types from '@/store/mutation-types'

const state = {
  colleges: JSON.parse(localStorage.getItem('colleges')) || [],
  collegeCompetitions:
    JSON.parse(localStorage.getItem('collegeCompetitions')) || []
}

const getters = {
  colleges: state => state.colleges,
  collegesCheck: state => state.colleges.length > 0,

  collegeCompetitions: state => state.collegeCompetitions,
  collegeCompetitionsCheck: state => state.collegeCompetitions.length > 0
}

const mutations = {
  [types.SET_COLLEGES](state, { objects }) {
    state.colleges = objects
    localStorage.setItem('colleges', JSON.stringify(objects))
  },
  [types.REMOVE_COLLEGES](state) {
    state.colleges = []
    localStorage.removeItem('colleges')
  },

  [types.SET_COLLEGE_COMPETITIONS](state, { objects }) {
    state.collegeCompetitions = objects
    localStorage.setItem('collegeCompetitions', JSON.stringify(objects))
  },
  [types.REMOVE_COLLEGE_COMPETITIONS](state) {
    state.collegeCompetitions = []
    localStorage.removeItem('collegeCompetitions')
  }
}

const actions = {
  async getColleges({ commit, getters }) {
    return getObjects(
      commit,
      'accelerators/colleges/',
      types.SET_COLLEGES,
      getters.collegesCheck,
      getters.colleges
    )
  },

  async getCollegeCompetitions({ commit, getters }) {
    return getObjects(
      commit,
      'accelerators/competitions/',
      types.SET_COLLEGE_COMPETITIONS,
      getters.collegeCompetitionsCheck,
      getters.collegeCompetitions
    )
  }
}

export default {
  getters,
  state,
  mutations,
  actions
}
