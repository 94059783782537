// import axios from 'axios'
import router from '@/router'
import store from '@/store'

const authService = {
  getRoute(nextQuery = null) {
    const { getters } = store

    if (nextQuery) {
      if (
        (getters['auth/checkOperator'] && !nextQuery.includes('lk')) ||
        (getters['auth/checkEntrant'] && !nextQuery.includes('admin'))
      ) {
        return nextQuery
      }
    }

    if (getters['auth/checkOperator']) {
      return {
        name: 'admin.search'
      }
    }

    if (getters['auth/checkEntrant']) {
      // return {
      //   name: 'lk.profile'
      // }
      return {
        name: 'lk.statements.list'
      }
    }

    return '/'
  },

  /**
   * Вход пользователя
   *
   * @param {string} username Имя пользователя
   * @param {string} password Пароль
   */
  login(username, password) {
    const { dispatch } = store

    return new Promise((resolve, reject) => {
      dispatch('auth/login', { username, password })
        .then(response => {
          const next = router.currentRoute.query.next || null
          router.push(this.getRoute(next))
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /**
   * Выхода пользователя
   *
   * @param {boolean} dismiss Признак истечения сеанса
   */
  logout(dismiss = false) {
    const { dispatch } = store
    dispatch('auth/logout').then(() => {
      if (!router.currentRoute.meta.isPublic) {
        router.push({
          name: 'login',
          query: {
            next: router.currentRoute.fullPath,
            dismiss: dismiss ? true : undefined
          }
        })
      }
      dispatch('reference/clear')
    })
  },

  /**
   * Обновление данных о пользователе
   */
  info() {
    const { dispatch } = store
    return new Promise((resolve, reject) => {
      dispatch('auth/info')
        .then(() => {
          resolve({})
        })
        .catch(err => {
          this.logout(true)
          reject(err)
        })
    })
  }
}

window.addEventListener('storage', event => {
  if (event.key === 'payload') {
    if (!event.newValue) {
      authService.logout(true)
      return
    }
    const { commit } = store
    const payload = localStorage.getItem('payload')
    commit('auth/SET_PAYLOAD', { payload })
  }
})

export default authService
