<template>
  <span
    v-if="widgetProps"
    :style="{ background: widgetProps.color }"
    class="search-item__registration-type"
  >
    {{ widgetProps.label }}
  </span>
</template>

<script>
  const typesList = {
    default: { color: '#138496', label: 'Не определен' },
    0: { color: 'red', label: 'Не завершен' },
    1: { color: 'green', label: 'Самостоятельно' },
    2: { color: 'midnightblue', label: 'Университет' },
    3: { color: 'mediumpurple', label: 'ЕПГУ' }
  }
  export default {
    name: 'registration-type-widget',
    props: {
      registrationTypeId: {
        type: Number,
        required: false
      }
    },
    computed: {
      widgetProps: function() {
        return typesList[this?.registrationTypeId || 'default']
      }
    }
  }
</script>
