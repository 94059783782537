import include from '@/utils/router-include'
import statements from './statements'
import accelerators from './accelerators'
import contracts from './contracts'
import reports from './reports'
import exams from './exams'
import enrollment from './enrollment'

const Search = () =>
  import(/* webpackChunkName: "admin__search" */ '@/pages/admin/search').then(
    m => m.default || m
  )
const SearchNew = () =>
  import(
    /* webpackChunkName: "admin__search-new" */ '@/pages/admin/search-new'
  ).then(m => m.default || m)
const SearchEdit = () =>
  import(
    /* webpackChunkName: "admin__search-edit" */ '@/pages/admin/search-edit'
  ).then(m => m.default || m)
const Add = () =>
  import(
    /* webpackChunkName: "admin__add" */ '@/pages/registration/admin/'
  ).then(m => m.default || m)
const CheckEntrant = () =>
  // import(/* webpackChunkName: "admin__add" */ '@/pages/admin/add').then(
  import(
    /* webpackChunkName: "admin__check-entrant" */ '@/pages/admin/check-entrant'
  ).then(m => m.default || m)
const Deanery = () =>
  import(
    /* webpackChunkName: "admin__branches" */ '@/pages/admin/reports/branches'
  ).then(m => m.default || m)

const Questionnaire = () =>
  import(
    /* webpackChunkName: "admin__questionnaire" */ '@/pages/admin/questionnaire/'
  ).then(m => m.default || m)

const Statistics = () =>
  import(
    /* webpackChunkName: "admin__statistics" */ '@/pages/admin/statistics'
  ).then(m => m.default || m)

export default [
  { path: '/', name: 'search', component: Search },
  { path: '/add/:userId', name: 'add', component: Add },
  { path: '/check-entrant/', name: 'check-entrant', component: CheckEntrant },

  { path: '/deanery/', name: 'deanery', component: Deanery },
  { path: '/search-new/', name: 'search-new', component: SearchNew },
  { path: '/search-edit/', name: 'search-edit', component: SearchEdit },
  { path: '/statistics/', name: 'statistics', component: Statistics },
  {
    path: '/questionnaire/:id',
    name: 'questionnaire',
    component: Questionnaire
  },

  ...include('/contracts/', contracts, 'contracts'),
  ...include('/statements/', statements, 'statements'),
  ...include('/accelerators/', accelerators, 'accelerator'),
  ...include('/reports/', reports, 'reports'),
  ...include('/exams/', exams, 'exams'),
  ...include('/enrollment/', enrollment, 'enrollment')
]
