<template>
  <div>
    <navbar @click="toogleSidebar" />
    <div class="d-flex wrapper" ref="wrapper">
      <sidebar :is-admin="!isEntrant" />
      <div class="content">
        <div class="overlay" @click="toogleSidebar"></div>
        <child />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import $ from 'jquery'

  export default {
    name: 'MainLayout',
    computed: {
      ...mapGetters({
        isEntrant: 'auth/checkEntrant'
      })
    },
    methods: {
      toogleSidebar: function(e) {
        $(this.$refs.wrapper).toggleClass('toggle-sidebar')
      }
    }
  }
</script>
