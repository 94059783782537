import getObjects from './base'
import * as types from '@/store/mutation-types'

const state = {
  identityDocumentTypes:
    JSON.parse(localStorage.getItem('identityDocumentTypes')) || [],
  educationDocumentTypes:
    JSON.parse(localStorage.getItem('educationDocumentTypes')) || []
}

const getters = {
  identityDocumentTypes: state => state.identityDocumentTypes,
  identityDocumentTypesCheck: state => state.identityDocumentTypes.length > 0,

  educationDocumentTypes: state => state.educationDocumentTypes,
  educationDocumentTypesCheck: state => state.educationDocumentTypes.length > 0
}

const mutations = {
  [types.SET_IDENTITY_DOCUMENT_TYPES](state, { objects }) {
    state.identityDocumentTypes = objects
    localStorage.setItem('identityDocumentTypes', JSON.stringify(objects))
  },
  [types.REMOVE_IDENTITY_DOCUMENT_TYPES](state) {
    state.identityDocumentTypes = []
    localStorage.removeItem('identityDocumentTypes')
  },

  [types.SET_EDUCATION_DOCUMENT_TYPES](state, { objects }) {
    state.educationDocumentTypes = objects
    localStorage.setItem('educationDocumentTypes', JSON.stringify(objects))
  },
  [types.REMOVE_EDUCATION_DOCUMENT_TYPES](state) {
    state.educationDocumentTypes = []
    localStorage.removeItem('educationDocumentTypes')
  }
}

const actions = {
  async getIdentityDocumentTypes({ commit, getters }) {
    return getObjects(
      commit,
      'others/identity-document-types/',
      types.SET_IDENTITY_DOCUMENT_TYPES,
      getters.identityDocumentTypesCheck,
      getters.identityDocumentTypes
    )
  },

  async getEducationDocumentTypes({ commit, getters }) {
    return getObjects(
      commit,
      'others/education-document-types/',
      types.SET_EDUCATION_DOCUMENT_TYPES,
      getters.educationDocumentTypesCheck,
      getters.educationDocumentTypes
    )
  }
}

export default {
  getters,
  state,
  mutations,
  actions
}
