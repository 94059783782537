import Vue from 'vue'
import { ToggleButton } from 'vue-js-toggle-button'
import Multiselect from 'vue-multiselect'

import Child from './layouts/Child'
import Navbar from './layouts/TheNavbar'
import Sidebar from './layouts/TheSidebar'

import Button from './ui/Button'
import ButtonLink from './ui/ButtonLink'
import Dialog from './ui/Dialog'
import EntityStatus from './ui/EntityStatus'
import Fileinput from './ui/Fileinput'
import AppMultiselect from './ui/Multiselect'
import Numbox from './ui/Numbox'
import Phoneinput from './ui/Phoneinput'
import Radio from './ui/Radio'
import Radiogroup from './ui/Radiogroup'
import RegistrationTypeWidget from './ui/RegistrationTypeWidget'
import Select from './ui/Select'
import Textbox from './ui/Textbox'
import Tooltip from './ui/Tooltip'

import Example from './ui/Example'
;[
  Child,
  Navbar,
  Sidebar,
  Textbox,
  Numbox,
  Radio,
  Button,
  ButtonLink,
  Select,
  AppMultiselect,
  Fileinput,
  Phoneinput,
  Radiogroup,
  RegistrationTypeWidget,
  Dialog,
  Tooltip,
  {
    ...EntityStatus,
    name: 'entity-status'
  },
  {
    ...Multiselect,
    name: 'multiselect'
  },
  ToggleButton,
  {
    name: 'example',
    ...Example
  }
  // if not component name
  // { name: 'component-name', ...Component }
].forEach(Component => {
  if (!Component.name) {
    throw new Error(`Not component name: ${Component}`)
  }

  Vue.component(Component.name, Component)
})
