import store from '@/store'

export default (to, from, next) => {
  const stepPageName = 'lk.add'

  if (store.getters['auth/checkEntrant']) {
    if (store.getters['auth/checkFinishStepAdd']) {
      if (to.name === stepPageName) {
        next({ name: 'lk.profile' })
        return
      }

      next()
      return
    }

    if (to.name === stepPageName) {
      next()
      return
    }

    next({ name: stepPageName })
    return
  }
  next({ name: 'protected' })
}
