<template>
  <vue-tel-input
    :value="value"
    :inputOptions="{ showDialCode: true }"
    :defaultCountry="code ? code : 'RU'"
    @input="input"
    @country-changed="countryChanged"
    :inputId="uuid"
  />
</template>

<script>
  import { v1 as uuidv1 } from 'uuid'

  export default {
    name: 'app-phoneinput',
    props: {
      value: String,
      code: String,
      valid: Boolean
    },
    data() {
      return {
        change: false
      }
    },
    computed: {
      uuid: () => uuidv1()
    },
    methods: {
      input(phone, payload) {
        this.change = true
        this.$emit('input', payload.number.international)
        this.$emit('update:code', payload.regionCode)
        this.$emit('update:valid', payload.valid)
      },
      countryChanged(payload) {
        if (this.change) {
          this.$emit('input', '')
          this.$emit('update:code', payload.iso2)
          this.$emit('update:valid', false)
        }
      }
    }
  }
</script>
