<script>
  import Swal from 'sweetalert2'

  export default {
    methods: {
      showValidationMessage(message) {
        Swal.showValidationMessage(message)
      },
      showModal(component, props, events = {}) {
        const options = {
          scrollable: true,
          adaptive: true,
          height: 'auto',
          clickToClose: false
        }

        this.$modal.show(component, props, options, events)
      },
      showSwal(config, callback) {
        Swal.fire(config).then(callback)
      },
      showConfirm({
        text = 'Ошибка',
        title = 'Внимание',
        confirmButtonText = 'Продолжить',
        callback = null,
        icon = 'info',
        preConfirm = null
      }) {
        const config = {
          title: title,
          text: text,
          icon: icon,
          showCancelButton: true,
          html: text,
          allowOutsideClick: false,
          confirmButtonColor: '#17a2b8',
          cancelButtonColor: '#dc3545',
          cancelButtonText: 'Отмена',
          showLoaderOnConfirm: true,
          confirmButtonText: confirmButtonText
        }

        if (preConfirm) {
          config.allowOutsideClick = () => !Swal.isLoading()
          config.preConfirm = preConfirm
        }

        Swal.fire(config).then(result => {
          if (result.value) {
            if (callback) {
              callback(result)
            }
          }
        })
      },
      showError(text = 'Ошибка', title = 'Ошибка') {
        Swal.fire(title, text, 'error')
      },
      showSuccess() {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          onOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })

        Toast.fire({
          icon: 'success',
          title: 'Успех'
        })
      },
      showPreloader(text = 'Сохранение данных') {
        Swal.fire({
          title: 'Подождите',
          html: text,
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          }
        })
      },
      hidePreloader() {
        Swal.close()
      }
    }
  }
</script>
