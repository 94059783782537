<template>
  <v-easy-dialog v-model="localModelValue">
    <div class="card px-4 py-4">
      <slot name="title">
        <div class="d-flex">
          <h4>{{ title }}</h4>
          <i
            class="mdi mdi-24px mdi-close cursor-pointer"
            @click="closeDialog"
          ></i>
        </div>
      </slot>
      <slot></slot>
      <div class="ml-auto">
        <slot name="buttons"></slot>
        <app-button
          @click="closeDialog"
          text="Закрыть"
          background="bg-danger"
          class="text-white"
          icon="mdi mdi-close"
        />
      </div>
    </div>
  </v-easy-dialog>
</template>

<script>
import VEasyDialog from 'v-easy-dialog'

export default {
  name: 'app-dialog',
  components: {
    VEasyDialog
  },
  props: {
    title: String,
    modelValue: Boolean
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:modelValue', false)
    }
  }
}
</script>
