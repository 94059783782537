import getObjects from './base'
import * as types from '@/store/mutation-types'

const state = {
  admissionCampaigns:
    JSON.parse(localStorage.getItem('admissionCampaigns')) || [],
  admissionDepartments:
    JSON.parse(localStorage.getItem('admissionDepartments')) || [],
  branches:
    JSON.parse(localStorage.getItem('branches')) || []
}

const getters = {
  admissionCampaigns: state => state.admissionCampaigns,
  admissionCampaignsCheck: state => state.admissionCampaigns.length > 0,

  admissionDepartments: state => state.admissionDepartments,
  admissionDepartmentsCheck: state => state.admissionDepartments.length > 0,

  branches: state => state.branches,
  branchesCheck: state => state.branches.length > 0
}

const mutations = {
  [types.SET_ADMISSION_CAMPAIGNS](state, { objects }) {
    state.admissionCampaigns = objects
    localStorage.setItem('admissionCampaigns', JSON.stringify(objects))
  },
  [types.REMOVE_ADMISSION_CAMPAIGNS](state) {
    state.admissionCampaigns = []
    localStorage.removeItem('admissionCampaigns')
  },

  [types.SET_ADMISSION_DEPARTMENTS](state, { objects }) {
    state.admissionDepartments = objects
    localStorage.setItem('admissionDepartments', JSON.stringify(objects))
  },
  [types.REMOVE_ADMISSION_DEPARTMENTS](state) {
    state.admissionDepartments = []
    localStorage.removeItem('admissionDepartments')
  },

  [types.SET_BRANCHES](state, { objects }) {
    state.branches = objects
    localStorage.setItem('branches', JSON.stringify(objects))
  },
  [types.REMOVE_BRANCHES](state) {
    state.branches = []
    localStorage.removeItem('branches')
  }
}

const actions = {
  async getAdmissionCampaigns({ commit, getters }) {
    return getObjects(
      commit,
      'competitions/admission-campaigns/',
      types.SET_ADMISSION_CAMPAIGNS,
      getters.admissionCampaignsCheck,
      getters.admissionCampaigns
    )
  },

  async getAdmissionDepartments({ commit, getters }) {
    return getObjects(
      commit,
      'subdivisions/admission-departments/',
      types.SET_ADMISSION_DEPARTMENTS,
      getters.admissionDepartmentsCheck,
      getters.admissionDepartments
    )
  },

  async getBranches({ commit, getters }) {
    return getObjects(
      commit,
      'subdivisions/branches/',
      types.SET_ADMISSION_DEPARTMENTS,
      getters.branchesCheck,
      getters.branches
    )
  }
}

export default {
  getters,
  state,
  mutations,
  actions
}
