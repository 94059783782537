import Vue from 'vue'
import store from '@/store'
import IdleVue from 'idle-vue'

const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 20 * 60000, // 15 минут
  startAtIdle: false,
  events: ['mousemove', 'keydown', 'mousedown', 'touchstart', 'scroll']
})
