import store from '@/store'

export default (to, from, next) => {
  if (to.meta.isPublic) {
    next()
    return
  }

  if (!store.getters['auth/check']) {
    next({
      name: 'login',
      query: { next: to.fullPath }
    })
  } else {
    next()
  }
}
